// TODO generate this config at build time by reading values from from env variables or buildspec
// calling out to the orsnn-app-api-gamma stack to get the values
const config = {
  apiGateway: {
    REGION: 'us-west-2',
    URL: 'https://hpl0ttp5hg.execute-api.us-west-2.amazonaws.com/prod/graphql',
  },
  logs: {
    REGION: 'us-west-2',
    URL: 'https://hpl0ttp5hg.execute-api.us-west-2.amazonaws.com/prod/log',
  },
  cognito: {
    REGION: 'us-west-2',
    USER_POOL_ID: 'us-west-2_a88WrCAuN',
    APP_CLIENT_ID: '58k0p6vlj8ocmuqmqef1ujdun0',
    IDENTITY_POOL_ID: 'us-west-2:9fca6f80-427f-4886-bd82-5de6b6deccd0',
  },
  fileGateway: {
    REGION: 'us-west-2',
    URL: 'https://hpl0ttp5hg.execute-api.us-west-2.amazonaws.com/prod/files',
  },
  smartHeaderGateway: {
    REGION: 'us-west-2',
    URL: 'https://hpl0ttp5hg.execute-api.us-west-2.amazonaws.com/prod/smart_header_mapping',
  },
  website: {
    URL: 'https://gamma.orsnn.com',
  },
  pinpoint: {
    REGION: 'us-west-2',
    APP_ID: 'c0d341d1d4e54a05a68d6ac5657cfca1',
  },
  features: {
    isUnsecEnabled: true,
  },
} as const;

export default config;
